import {useMemo, useRef} from 'react';

import {FAQ} from '@/components/common/faq/FAQ';
import {Section, SECTION_STYLES} from '@/components/common/section/Section';
import {Title} from '@/components/common/title/Title';
import {questions} from '@/components/page/vk-work-space/faq-section/questions';
import {FEATURES} from '@/feature-flags';
import {useOpenCountriesLightboxByClick} from '@/hooks/use-open-countries-lightbox';

import styles from './FaqSectoin.module.css';

type FaqSectionProps = {
	id?: string;
};

export function FaqSection({id}: FaqSectionProps) {
	const titleRef = useRef();

	const openFaq = useOpenCountriesLightboxByClick();
	const questionsForMainPage = useMemo(() => {
		const updatedQuestions = [...questions];
		if (FEATURES.IS_SNG_USER) {
			updatedQuestions.splice(-1, 0, {
				question: 'Возможна ли оплата тарифа не в рублях, а в другой валюте?',
				answer: (
					<>
						Оставьте заявку, если&nbsp;хотели&nbsp;бы&nbsp;оплатить тариф VK WorkSpace SaaS
						не&nbsp;в&nbsp;рублях, а&nbsp;в&nbsp;другой валюте&nbsp;&mdash;&nbsp;
						<span onClick={openFaq} className={styles.ref}>
							мы&nbsp;подберём решение
						</span>
					</>
				),
			});
		}
		return updatedQuestions;
	}, [openFaq]);

	return (
		<Section style={SECTION_STYLES.WITH_BREAKPOINTS} sectionId={id} className={styles.root}>
			<Title with1680={false} className={styles.title} ref={titleRef}>
				Частые вопросы о&nbsp;платформе
			</Title>
			<FAQ
				questions={questionsForMainPage}
				questionClassName={styles.question}
				answerClassName={styles.answer}
				questionRootClassName={styles.questionRoot}
			/>
		</Section>
	);
}

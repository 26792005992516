import classNames from 'classnames';

import {Button} from '@/components/base/button/Button';
import {Typography, VARIANTS} from '@/components/base/typography/Typography';
import Image from '@/components/common/image/Image';
import {Section, SECTION_STYLES} from '@/components/common/section/Section';
import {LINKS} from '@/constants';
import {Media, MediaContextProvider} from '@/media';

import IconCards from './assets/cards_2_outline_28.svg';
import IconComputer from './assets/computer_smartphone_outline_28.svg';
import VkTechImg from './assets/vk-tech-img.webp';
import IconBag from './assets/work_outline_28.svg';
import styles from './VkTechBanner.module.css';

export function VkTechBanner() {
	return (
		<Section
			isFullWidth
			style={SECTION_STYLES.WITH_BREAKPOINTS}
			direction="column"
			className={styles.root}
		>
			<div className={styles.grid}>
				<div className={classNames(styles.card, styles.bigCard)}>
					<Typography variant={VARIANTS.h2} className={styles.title}>
						25 лет
						<br />
						технологической
						<br />
						экспертизы
					</Typography>
					<div className={styles.imageWrapper}>
						<MediaContextProvider>
							<Media lessThan="desktop" className={styles.wrapper}>
								<Image
									width={256}
									height={176}
									src={VkTechImg}
									className={styles.vkTechImg}
									alt="VK tech"
								/>
							</Media>
							<Media at="desktop" className={styles.wrapper}>
								<Image
									width={277}
									height={191}
									src={VkTechImg}
									className={styles.vkTechImg}
									alt="VK tech"
								/>
							</Media>
							<Media greaterThanOrEqual="desktop_xl" className={styles.wrapper}>
								<Image
									width={437}
									height={301}
									src={VkTechImg}
									className={styles.vkTechImg}
									alt="VK tech"
								/>
							</Media>
						</MediaContextProvider>
					</div>
					<Button as="a" href={LINKS.vkTech} className={styles.button} target="_blank">
						Подробнее
					</Button>
				</div>
				<div className={classNames(styles.card, styles.cardWithText)}>
					<Typography variant={VARIANTS.h3} className={styles.cardTitle}>
						VK WorkSpace — продукт VK Tech, российского вендора корпоративного ПО
					</Typography>
				</div>
				<div className={classNames(styles.card, styles.cardWithIcon)}>
					<div className={styles.iconWrapper}>
						<IconBag className={styles.icon} />
					</div>
					<Typography variant={VARIANTS.text} className={styles.cardText}>
						Комплексный портфель ИТ-решений
					</Typography>
				</div>
				<div className={classNames(styles.card, styles.cardWithIcon)}>
					<div className={styles.iconWrapper}>
						<IconComputer className={styles.icon} />
					</div>
					<Typography variant={VARIANTS.text} className={styles.cardText}>
						Софтверные продукты для ежедневных задач
					</Typography>
				</div>
				<div className={classNames(styles.card, styles.cardWithIcon)}>
					<div className={styles.iconWrapper}>
						<IconCards className={styles.icon} />
					</div>
					<Typography variant={VARIANTS.text} className={styles.cardText}>
						Более 20 продуктов в реестре отечественного ПО
					</Typography>
				</div>
			</div>
		</Section>
	);
}

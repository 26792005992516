import classNames from 'classnames';
import type {NextPage} from 'next';
import Head from 'next/head';
import {useRouter} from 'next/router';

import {Companies} from '@/components/common/companies/Companies';
import Footer from '@/components/common/footer/Footer';
import {Instruments} from '@/components/common/instruments-v2/Instruments';
import {SchemaJSON} from '@/components/common/schema-json/SchemaJSON';
import {TemplateForHiddenHeader} from '@/components/common/template-for-hidden-header/TemplateForHiddenHeader';
import {Variants} from '@/components/common/variants-v2/Variants';
import appleTouchIcon from '@/components/page/vk-work-space/assets/apple-touch-icon.png';
import favicon from '@/components/page/vk-work-space/assets/favicon.ico';
import favicon16 from '@/components/page/vk-work-space/assets/favicon-16x16.png';
import favicon32 from '@/components/page/vk-work-space/assets/favicon-32x32.png';
import ogImage from '@/components/page/vk-work-space/assets/vk-work-space-og.png';
import {BusinessBanner} from '@/components/page/vk-work-space/business-banner/BusinessBanner';
import {Cases} from '@/components/page/vk-work-space/cases/Cases';
import {ANCHORS} from '@/components/page/vk-work-space/const';
import {FaqSection} from '@/components/page/vk-work-space/faq-section/FaqSection';
import {Promo} from '@/components/page/vk-work-space/promo/Promo';
import {Request} from '@/components/page/vk-work-space/request/Request';
import {ServicesCommunication} from '@/components/page/vk-work-space/services-communication/ServicesCommunication';
import {VkTechBanner} from '@/components/page/vk-work-space/vk-tech-banner/VkTechBanner';
import {ProjectHrefs, TEMPORARY_LINKS_DATES} from '@/constants';
import {withThemeVariables} from '@/hocs/with-theme-variables';
import {useCheckOpenedModal} from '@/hooks/use-check-opened-modal';
import {useInjection} from '@/hooks/use-injection';
import {useIsDateTimeInRange} from '@/hooks/use-is-date-time-in-range';
import {useOnceWhenEffect} from '@/hooks/use-once-when-effect';
import {useOpenCountriesLightboxOnInitApp} from '@/hooks/use-open-countries-lightbox';
import {useStartFeatures} from '@/hooks/use-start-features';
import {useSuccessSignUp} from '@/hooks/use-success-sign-up';
import {IFeatureService} from '@/services/feature/IFeatureService';
import {UAEventType, YAReachGoalId} from '@/services/metrics/constants';
import {IMetricsService} from '@/services/metrics/IMetricsService';
import {getCanonicalUrl} from '@/utilites/get-canonical-url';
import getUrlWithoutSignUpQuery from '@/utilites/modify-query/get-url-without-sign-up-query';
import {insertJivoScript} from '@/utilites/script/insert-jivo-script';

import styles from './vk-workspace.module.css';
import variablesStyles from './vk-workspace-variables.module.css';

const title = 'VK WorkSpace | коммуникационная платформа для бизнеса от VK';
const description =
	'Корпоративная почта с календарем и адресной книгой, мессенджер с аудио- и видеозвонками, облачное хранилище со встроенным редактором документов';

interface VKWorkSpaceProps {}

const VKWorkSpace: NextPage<VKWorkSpaceProps> = () => {
	const router = useRouter();
	const {isMatchDate} = useIsDateTimeInRange(
		TEMPORARY_LINKS_DATES[ProjectHrefs.promo].start,
		TEMPORARY_LINKS_DATES[ProjectHrefs.promo].end,
	);
	const canonicalUrl = getCanonicalUrl('workSpace');

	const [featureService] = useInjection(IFeatureService);
	const [metricsService] = useInjection(IMetricsService);

	useSuccessSignUp(() => {
		metricsService.sendEvent({
			yaGoals: [YAReachGoalId.WorkspaceClickRegister],
			uaEvents: [
				{
					type: UAEventType.MainEvent,
					payload: {
						category: 'verify',
						action: 'send',
						label: 'workspace',
					},
				},
			],
		});

		const asPathWithoutQuery = getUrlWithoutSignUpQuery(router.asPath);
		router.push(router.route, asPathWithoutQuery, {shallow: true});
	});

	useStartFeatures();
	useCheckOpenedModal();
	useOnceWhenEffect(() => featureService.isServiceStarted, insertJivoScript);
	useOpenCountriesLightboxOnInitApp();

	return (
		<>
			<div className={classNames(styles.root, variablesStyles.cssLocalVariables)}>
				<Head>
					<title>{title}</title>
					<meta name="yandex-verification" content="e48d7f7aef162c37" />
					<meta name="description" content={description} />

					<meta property="og:url" content={canonicalUrl} />
					<meta property="og:title" content={title} />
					<meta property="og:description" content={description} />
					<meta property="og:image" content={ogImage.src} />

					<link rel="canonical" href={canonicalUrl} />
					<link rel="icon" href={favicon.src} />
					<link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon.src} />
					<link rel="icon" type="image/png" sizes="32x32" href={favicon32.src} />
					<link rel="icon" type="image/png" sizes="16x16" href={favicon16.src} />
					<meta name="msapplication-TileColor" content="#2d89ef" />
					<meta name="theme-color" content="#111111" />
				</Head>

				<TemplateForHiddenHeader>
					<Promo id={ANCHORS.MAIN} />
					{isMatchDate && <BusinessBanner />}
					<Instruments id={ANCHORS.ABOUT_SERVICES} />
					<Variants />
					<Companies />
					<ServicesCommunication id={ANCHORS.OUR_EXCELLENCE} />
					<Cases />
					<FaqSection />
					<Request id={ANCHORS.REQUEST} />
					<VkTechBanner />
					<Footer />
				</TemplateForHiddenHeader>
				<SchemaJSON />
			</div>
		</>
	);
};

export default withThemeVariables(VKWorkSpace, variablesStyles.cssLocalVariables);
